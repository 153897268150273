import React from 'react';
import DashboardWrapper from '../../containers/Dashboard/DashboardWrapper';
import CourseWrapper from '../../containers/Dashboard/CourseWrapper/CourseWrapper';

const CoursePage = ({ location, pageContext }) => {
  return (
    <DashboardWrapper
      noLayout={true}
      location={location}
      pageContext={pageContext}
    >
      <CourseWrapper location={location} />
    </DashboardWrapper>
  );
};

export default CoursePage;
