import { api_base_url } from '../../../../data/client';
import axios from 'axios';

const update_achievement = async ({
  lecture_id,
  userGlobalState,
  course_id,
  to_add
}) => {
  const { _id } = userGlobalState.user;

  const course_res = await axios({
    url: `${api_base_url}/mentees/achievement`,
    method: 'PATCH',
    headers: {
      // "Authorization": `Bearer ${token}`,
      'Content-type': 'application/json; charset=utf-8'
    },
    data: JSON.stringify({
      user_id: _id,
      course_id,
      lecture_id,
      to_add
    })
  });
};

export default update_achievement;
