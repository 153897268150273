import { api_base_url } from '../../../../data/client';
import axios from 'axios';

const fetch_data = async ({ userGlobalState, setState, course_id }) => {
  const { _id } = userGlobalState.user;

  const token = localStorage.getItem('s_t_kmp');

  const course_p = axios({
    url: `${api_base_url}/courses/${course_id}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const achievement_p = axios({
    url: `${api_base_url}/mentees/achievement`,
    method: 'POST',
    headers: {
      // "Authorization": `Bearer ${token}`,
      'Content-type': 'application/json; charset=utf-8'
    },
    data: JSON.stringify({
      user_id: _id,
      course_id
    })
  });

  const [course_res, achievement_res] = await Promise.all([
    course_p,
    achievement_p
  ]);

  const course = course_res.data;
  const lectures_completed = achievement_res.data.lectures_completed || [];

  const lectures_completed_obj = createCompletedLecturesObj({
    lectures_completed
  });

  const lecturesFlatArr = getLecturesFlatArr({ course });

  setState({
    isLoading: false,
    userLectures: lectures_completed_obj,
    course,
    lecturesFlatArr,
    activeLecture: course.sections[0].lectures[0]
  });
};

export default fetch_data;

const createCompletedLecturesObj = ({ lectures_completed }) => {
  const obj = {};

  lectures_completed.forEach((lecture_id) => {
    obj[lecture_id] = true;
  });

  return obj;
};

const getLecturesFlatArr = ({ course }) => {
  const lecturesFlatArr = [];

  course.sections.forEach((section) => {
    section.lectures.forEach((lecture) => {
      lecturesFlatArr.push(lecture);
    });
  });

  return lecturesFlatArr;
};
