import React, { useState, useEffect, useContext } from 'react';
import useSetState from '../../../utils/useSetState';
import { ItemContainer, Spinner } from '../../../components';
import styles from './course.module.css';
import fetch_data from './utils/fetch_data';
import update_achievement from './utils/update_achievement';
import query_string from 'query-string';
import Checkbox from '@material-ui/core/Checkbox';
import { cloneDeep } from 'lodash';
import { UserContext } from '../../UserContext/UserContext';
import { Link } from 'gatsby';
import {
  SkipNext,
  SkipPrevious,
  Home,
  ExpandMore,
  ExpandLess,
  List as ListIcon
} from '@material-ui/icons';
import { useMediaQuery, Drawer } from '@material-ui/core';

const Student = ({ location }) => {
  const [state, setState] = useSetState({
    course: null,
    activeLecture: null,
    activeLectureIndex: 0,
    isLoading: true,
    userLectures: null,
    lecturesFlatArr: null,
    isMenuDrawerOpen: false
  });

  const isTabletOrUnder = useMediaQuery('(max-width:850px)');

  const { userGlobalState, setUserGlobalState } = useContext(UserContext);

  const changeLecture = (newLecture) => {
    // Without this, the activeLectureIndex is not synced with the activeLecture on the controllers
    const newLectureIndex = state.lecturesFlatArr.findIndex((lecture) => {
      return lecture._id == newLecture._id;
    });

    setState({
      activeLecture: newLecture,
      activeLectureIndex: newLectureIndex
    });
  };

  const jumpLecture = ({ type }) => {
    const activeLectureIndex = state.activeLectureIndex;

    const newIndex =
      type === 'next' ? activeLectureIndex + 1 : activeLectureIndex - 1;

    setState({
      activeLectureIndex: newIndex,
      activeLecture: state.lecturesFlatArr[newIndex]
    });
  };

  const updateFinishStatus = (lecture_id, to_add) => {
    update_achievement({
      userGlobalState,
      course_id: state.course._id,
      to_add,
      lecture_id
    });

    const newUserLectures = cloneDeep(state.userLectures);

    newUserLectures[lecture_id] = !newUserLectures[lecture_id];

    setState({
      userLectures: newUserLectures
    });
  };

  useEffect(() => {
    const queryObj = query_string.parse(location.search);
    fetch_data({ setState, course_id: queryObj._id, userGlobalState });
  }, []);

  return (
    <div>
      {state.isLoading ? (
        <Spinner />
      ) : !isTabletOrUnder ? (
        <div className={styles.video_page}>
          <CourseLecturesMenu
            updateFinishStatus={updateFinishStatus}
            changeLecture={changeLecture}
            jumpLecture={jumpLecture}
            styles={styles}
            state={state}
            setState={setState}
            areControllersActive={true}
          />
          <ItemContainer
            toggleMenu={() => {}}
            item={state.activeLecture.item}
            type={state.activeLecture.type}
          />
        </div>
      ) : (
        <div className={styles.video_page}>
          <Drawer
            style={{ width: '400px' }}
            open={state.isMenuDrawerOpen}
            onClose={setState.bind(null, { isMenuDrawerOpen: false })}
          >
            <CourseLecturesMenu
              areControllersActive={false}
              styles={styles}
              updateFinishStatus={updateFinishStatus}
              changeLecture={changeLecture}
              jumpLecture={jumpLecture}
              state={state}
              setState={setState}
            />
          </Drawer>
          <ItemContainer
            toggleMenu={() => {}}
            item={state.activeLecture.item}
            type={state.activeLecture.type}
          />
          <Controllers
            state={state}
            setState={setState}
            jumpLecture={jumpLecture}
            lectures={state.lecturesFlatArr}
            activeLectureIndex={state.activeLectureIndex}
          />
        </div>
      )}
    </div>
  );
};

export default Student;

const SectionToggler = ({
  section,
  changeLecture,
  updateFinishStatus,
  userLectures,
  index,
  state
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className={styles.SectionCard}>
        <div
          onClick={setIsOpen.bind(null, !isOpen)}
          className={styles.SectionCard_text}
        >
          <span className={styles.SectionTitle}>
            Section {index + 1 + ': ' + section.title}
          </span>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </div>
        {isOpen
          ? (section.lectures || []).map((lecture, i) => {
              return (
                <LectureBox
                  state={state}
                  key={lecture._id + i}
                  lecture={lecture}
                  changeLecture={changeLecture}
                  updateFinishStatus={updateFinishStatus}
                  userLectures={userLectures}
                  section_index={index}
                  index={i}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

const LectureBox = ({
  lecture,
  changeLecture,
  updateFinishStatus,
  userLectures,
  section_index,
  index,
  state
}) => {
  const wasCompleted = userLectures[lecture._id] ? true : false;
  const isCurrentLecture = state.activeLecture._id == lecture._id;

  return (
    <div
      className={
        styles['lecture__info__box'] +
        ' ' +
        (isCurrentLecture ? styles['activeLecture'] : '')
      }
    >
      <div className={styles.checkbox}>
        <Checkbox
          checked={wasCompleted}
          onChange={updateFinishStatus.bind(null, lecture._id, !wasCompleted)}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </div>

      <div
        style={{ cursor: 'pointer', marginLeft: '7px' }}
        onClick={changeLecture.bind(null, lecture)}
      >
        {section_index +
          1 +
          '.' +
          (index + 1) +
          ' ' +
          getLectureCategorySymbol(lecture) +
          ' ' +
          lecture.title}
      </div>
    </div>
  );
};

const Controllers = ({
  jumpLecture,
  lectures,
  activeLectureIndex,
  state,
  setState
}) => {
  const isNextActive = activeLectureIndex < lectures.length - 1;
  const isPreviousActive = activeLectureIndex > 0;

  return (
    <div className={styles.menu_controllers}>
      <div className={styles.left_controllers}>
        <div>
          <Link to="/dashboard">
            <Home />
          </Link>
        </div>
        <div className={styles.menu_icon_controller}>
          <ListIcon
            onClick={setState.bind(null, {
              isMenuDrawerOpen: !state.isMenuDrawerOpen
            })}
          />
        </div>
      </div>
      <div>
        <div
          className={
            isPreviousActive
              ? styles.active_controller
              : styles.inactive_controller
          }
        >
          <SkipPrevious
            onClick={
              isPreviousActive
                ? jumpLecture.bind(null, { type: 'previous' })
                : () => {}
            }
          />
        </div>

        <div
          className={
            isNextActive ? styles.active_controller : styles.inactive_controller
          }
        >
          <SkipNext
            onClick={
              isNextActive ? jumpLecture.bind(null, { type: 'next' }) : () => {}
            }
          />
        </div>
      </div>
    </div>
  );
};

const CourseLecturesMenu = ({
  state,
  styles,
  updateFinishStatus,
  changeLecture,
  jumpLecture,
  setState,
  areControllersActive
}) => {
  return (
    <div className={styles.side_menu}>
      <div className={styles.menu_sections}>
        {state.course.sections.map((section, index) => {
          return (
            <SectionToggler
              state={state}
              key={section.title + index}
              section={section}
              changeLecture={changeLecture}
              updateFinishStatus={updateFinishStatus}
              userLectures={state.userLectures}
              index={index}
            />
          );
        })}
      </div>
      {areControllersActive ? (
        <Controllers
          jumpLecture={jumpLecture}
          state={state}
          setState={setState}
          lectures={state.lecturesFlatArr}
          activeLectureIndex={state.activeLectureIndex}
        />
      ) : null}
    </div>
  );
};

const getLectureCategorySymbol = (lecture) => {
  const lectureCategory = lecture.category;

  if (lectureCategory == 'code along') {
    return '🐛';
  } else if (lectureCategory == 'conceptual') {
    return '🐵';
  } else if (lectureCategory == 'note') {
    return '📒';
  } else if (lectureCategory == 'project') {
    return '🕵';
  } else if (lectureCategory == 'coding lesson') {
    return '🕹️';
  } else {
    return '';
  }
};
